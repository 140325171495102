import React, { useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import useResponsive from "../../../hooks/useResponsive";
import EnhancedTableHead from "./EnhancedTableHead";
import CustomTableFooter from "../../CustomTableFooter";

import {
  getCookie,
  convertCount,
  convertToCurrency
} from "../../../helpers/functions";
import { getComparator } from "../../../helpers/MuiTableFunctions";

const HoldingsTable = props => {
  const isMobileOrTablet = useResponsive("only", "sm");
  const { holdings, holdsTtlAvgAmntDirtyBase, holdingsTotalMarketValue } =
    props;

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // ..and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["holdings"] || 10
  );

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("HoldingName");
  const [page, setPage] = useState(0);

  const handleRequestSort = (event, property) => {
    // if (page > 0) setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  holdings.forEach(holding => {
    // needs number type for sorting!
    holding.Count = Number(holding.Count);
    holding.LastPrice = Number(holding.LastPrice);
    holding.AvgAmountDirtyBase = Number(holding.AvgAmountDirtyBase);
    holding.MarketValueBase = Number(holding.MarketValueBase);
  });

  // Only add the option to export data to this specific account, see CAR-95
  const isDataExportable = location.pathname.includes(
    "a3da3043-0c6d-11ef-8042-06b6716cbafb"
  );

  return (
    <TableContainer component={Box}>
      {holdings.length > 0 && (
        <Box
          sx={{
            maxWidth: "100vw",
            border: 0,
            overflow: "auto"
          }}
        >
          <Table sx={{ minWidth: 900, border: 0 }} aria-label="simple table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              isMobileOrTablet={isMobileOrTablet}
            />
            <TableBody>
              {(rowsPerPage > 0
                ? holdings
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : holdings.sort(getComparator(order, orderBy))
              ).map(holding => (
                <TableRow
                  key={holding.Name}
                  sx={{ "&:last-child td": { border: 0 } }}
                >
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell">
                      {holding.Name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell">
                      {convertCount(holding.Count)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      px: 0,
                      py: 0.5
                    }}
                  >
                    <Typography variant="fairTableCell">
                      {convertToCurrency(holding.LastPrice)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell">
                      {holding.CurrencyCode}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell">
                      {holding.LastPriceDate}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      px: 0,
                      py: 0.5
                    }}
                  >
                    <Typography variant="fairTableCell">
                      {convertToCurrency(holding.AvgAmountDirtyBase)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      px: 0,
                      py: 0.5
                    }}
                  >
                    <Typography variant="fairTableCell">
                      {convertToCurrency(holding.MarketValueBase)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5, textAlign: "right" }}>
                    <Typography variant="fairTableCell">
                      {holding.UnrealisedProfit + " %"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}

              {holdings.length > 0 && (
                <TableRow
                  sx={{
                    "&:last-child td": { border: 0 },
                    backgroundColor: "#FAFAFA"
                  }}
                >
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell"></Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell"></Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      px: 0,
                      py: 0.5
                    }}
                  >
                    <Typography variant="fairTableCell"></Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell"></Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell"></Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      px: 0,
                      py: 0.5
                    }}
                  >
                    <Typography variant="fairTableCell">
                      <b>
                        {(holdsTtlAvgAmntDirtyBase &&
                          convertToCurrency(holdsTtlAvgAmntDirtyBase)) ||
                          0}{" "}
                        SEK
                      </b>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      px: 0,
                      py: 0.5
                    }}
                  >
                    <Typography variant="fairTableCell">
                      <b>
                        {(holdingsTotalMarketValue &&
                          convertToCurrency(holdingsTotalMarketValue)) ||
                          0}{" "}
                        SEK
                      </b>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell"></Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      )}
      <CustomTableFooter
        type="holdings"
        totalRecords={holdings?.length || 0}
        data={holdings}
        isExportable={isDataExportable}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
      />
    </TableContainer>
  );
};

HoldingsTable.propTypes = {
  holdings: PropTypes.array,
  holdingsTotalMarketValue: PropTypes.string,
  holdsTtlAvgAmntDirtyBase: PropTypes.number
};

export default HoldingsTable;
