import React, { Fragment, useState } from "react";
import { format } from "date-fns";

import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import DashboardPaper from "../../../theme/styled/DashboardPaper";

import { getComparator } from "../../../helpers/MuiTableFunctions";

import usePoaTable from "./usePoaTable";
import AddPoaModal from "./AddPoaModal";
import EditPoaModal from "./EditPoaModal";
import DeletePoaModal from "./DeletePoaModal";

import CustomTableFooter from "../../CustomTableFooter";
import CustomToolbar from "./CustomToolbar";
import EnhancedTableHead from "./EnhancedTableHead";

const PoaTable = () => {
  const {
    loaded,
    addModal,
    toggleAddModal,
    editModal,
    toggleEditModal,
    deleteModal,
    toggleDeleteModal,
    poasList,
    customersList,
    copyList,
    setCopyList,
    agentData,
    refreshTable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handlePoaClick
  } = usePoaTable();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("AgentName");

  const handleRequestSort = (event, property) => {
    // if (page > 0) setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // converting dates to timestamps/numbers for sorting to work
  copyList.map(poa => {
    poa.StartDate = new Date(poa.StartDate).getTime() || 0;
    poa.EndDate = new Date(poa.EndDate).getTime() || 0;
  });

  return !loaded ? (
    <Box
      sx={{
        height: "100%",
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <DashboardPaper>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%" }}>
          <Typography variant="h6" align="left">
            Fullmakter
          </Typography>
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          <Link sx={{ cursor: "pointer" }} onClick={toggleAddModal}>
            <Typography variant="fairTableCell" color="primary.light">
              Skapa ny
            </Typography>
          </Link>
        </Box>
      </Box>
      <CustomToolbar
        poasList={poasList}
        setData={setCopyList}
        page={page}
        setPage={setPage}
      />
      <Box sx={{ maxWidth: "100vw", border: 0, overflow: "auto", mt: 2 }}>
        <Table
          aria-label="custom pagination table"
          sx={{ minWidth: 780, border: 0, tableLayout: "fixed" }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? copyList
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : copyList.sort(getComparator(order, orderBy))
            )
              // (copyList.length > 0 ? copyList : rows)
              .map((agent, key) => (
                <Fragment key={key}>
                  <TableRow
                    sx={{
                      backgroundColor:
                        agent.IsManual > 0 ? "inherit" : "grey.300",
                      cursor: "pointer"
                    }}
                    onClick={() => handlePoaClick(agent.PoaId)}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        pr: 1.5
                      }}
                    >
                      <Typography variant="fairTableCell">
                        {agent.toggled ? "-" : "+"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        pr: 1.5,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      <Typography variant="fairTableCell">
                        {agent.AgentName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        pr: 1.5,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      <Typography variant="fairTableCell">
                        {agent.PrincipalName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="fairTableCell">
                        {agent.Type === "View" ? "Insyn" : agent.Type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="fairTableCell">
                        {agent.IsManual > 0 ? "Manual" : "CrossKey"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="fairTableCell">
                        {agent.StartDate > 0
                          ? format(new Date(agent.StartDate), "yyyy-MM-dd")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="fairTableCell">
                        {agent.EndDate > 0
                          ? format(new Date(agent.EndDate), "yyyy-MM-dd")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        pr: 1.5,
                        color: "secondary.lighter"
                      }}
                    >
                      {agent.IsManual > 0 && (
                        <Fragment>
                          <Link
                            sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                            onClick={e => toggleEditModal(e, agent)}
                          >
                            <Typography
                              variant="fairTableCell"
                              color="primary.light"
                            >
                              Ändra
                            </Typography>
                          </Link>
                          {" / "}
                          <Link
                            sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                            onClick={e => toggleDeleteModal(e, agent)}
                          >
                            <Typography
                              variant="fairTableCell"
                              color="primary.light"
                            >
                              Ta bort
                            </Typography>
                          </Link>
                        </Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                  {agent?.toggled && (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        sx={{
                          pl: "5%"
                        }}
                      >
                        <Box
                          className="info-content"
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            width: "100%"
                          }}
                        >
                          <Box
                            className="agent-content"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: { xs: "100%", sm: "50%" }
                            }}
                          >
                            <Typography
                              variant="h7"
                              align="left"
                              sx={{
                                color: "primary.light",
                                fontWeight: "600",
                                mb: 1
                              }}
                            >
                              Fullmaktstagare
                            </Typography>
                            <Typography
                              variant="fairTableCell"
                              sx={{
                                my: 1
                              }}
                            >
                              <b>Namn: </b>
                              {agent.AgentName}
                            </Typography>
                            <Typography
                              variant="fairTableCell"
                              sx={{
                                my: 1
                              }}
                            >
                              <b>Personnummer: </b>
                              {agent.AgentIdentityNo}
                            </Typography>
                          </Box>
                          <Box
                            className="principal-content"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: { xs: "100%", sm: "50%" },
                              mt: { xs: 2, sm: 0 }
                            }}
                          >
                            <Typography
                              variant="h7"
                              align="left"
                              sx={{
                                color: "primary.light",
                                fontWeight: "600",
                                mb: 1
                              }}
                            >
                              Fullmaktsgivare
                            </Typography>
                            <Typography
                              variant="fairTableCell"
                              sx={{
                                my: 1
                              }}
                            >
                              <b>Name: </b>
                              {agent.PrincipalName}
                            </Typography>
                            <Typography
                              variant="fairTableCell"
                              sx={{
                                my: 1
                              }}
                            >
                              <b>Personnummer: </b>
                              {agent.PrincipalIdentityNo}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          className="comments"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mt: 1
                          }}
                        >
                          <Typography
                            variant="fairTableCell"
                            sx={{
                              my: 1
                            }}
                          >
                            <b>Kommentarer: </b>
                            {agent.Comment ? agent.Comment : "-"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            {copyList.length < 1 && (
              <TableRow>
                <TableCell colSpan={7} sx={{ border: 0 }}>
                  Inga resultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <CustomTableFooter
          type="poas"
          totalRecords={copyList?.length || 0}
          data={copyList}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </Box>
      <AddPoaModal
        open={addModal}
        toggleModal={toggleAddModal}
        customersList={customersList}
        refreshTable={refreshTable}
      />
      <EditPoaModal
        open={editModal}
        toggleModal={toggleEditModal}
        agent={agentData}
        customersList={customersList}
        refreshTable={refreshTable}
      />
      <DeletePoaModal
        open={deleteModal}
        toggleModal={toggleDeleteModal}
        agent={agentData}
        refreshTable={refreshTable}
      />
    </DashboardPaper>
  );
};

export default PoaTable;
