import { roundNumber } from "../../../helpers/functions";

const useFeesModal = props => {
  const { open, toggle, data, limitedFeeAnalysis } = props;

  const { feeTransactions, feeInvestment, rebatesTransactions } = data;

  feeTransactions.forEach(fee => {
    // we convert to number type for table sorting to work correctly
    fee.FeePaid = Number(fee.FeePaid);
    fee.FeeWithoutRebate = Number(fee.FeeWithoutRebate);
    fee.Earnings = Number(fee.FeeWithoutRebate - fee.FeePaid);
  });

  //order feeTransactions by fee.Date descending
  feeTransactions.sort((a, b) => new Date(b.Date) - new Date(a.Date));

  // calculate feeTotalSaved
  let feeTotalSaved = 0;
  feeTransactions.forEach(fee => {
    feeTotalSaved = feeTotalSaved + fee.Earnings;
  });
  feeTotalSaved = Number(feeTotalSaved);

  // calculate discountReturn
  const discountReturn = feeTotalSaved
    ? roundNumber((feeTotalSaved / feeInvestment) * 100, 2)
    : 0;

  return {
    open,
    toggle,
    limitedFeeAnalysis,
    feeInvestment,
    feeTotalSaved,
    discountReturn,
    rebatesTransactions,
    feeTransactions
  };
};

export default useFeesModal;
