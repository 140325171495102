// import logo from "./logo.svg";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper.jsx";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox.jsx";
import DashboardPaper from "../../theme/styled/DashboardPaper";

import useAuditLog from "./useAuditLog.js";
import AuditLogTable from "./AuditLogTable";

const AuditLogSection = () => {
  const {
    isMobile,
    loaded,
    logs,
    numLogs,
    page,
    setPage,
    handleChangePage,
    filters,
    setFilters,
    users,
    actions,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    handleLogClick
  } = useAuditLog();

  return (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Audit Logs
        </Typography>
        {/* {!isMobile && (
          <Typography variant="subtitle1">
            Här kan du se dina transaktioner
          </Typography>
        )} */}
      </SectionHeaderPaper>
      <SectionWrapperBox sx={{ flexFlow: "column", mx: 2.5, mt: 5 }}>
        <DashboardPaper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuditLogTable
              loaded={loaded}
              isMobile={isMobile}
              logs={logs}
              numLogs={numLogs}
              page={page}
              setPage={setPage}
              handleChangePage={handleChangePage}
              filters={filters}
              setFilters={setFilters}
              users={users}
              actions={actions}
              order={order}
              orderBy={orderBy}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleRequestSort={handleRequestSort}
              handleLogClick={handleLogClick}
            />
          </LocalizationProvider>
        </DashboardPaper>
      </SectionWrapperBox>
    </Box>
  );
};
export default AuditLogSection;
