import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import Link from "@mui/material/Link";

import {
  getCookie,
  convertShareAmount,
  convertToCurrency,
  convertTransactionAmount
} from "../../../helpers/functions";
import { getComparator } from "../../../helpers/MuiTableFunctions";

import CustomTableFooter from "../../CustomTableFooter";
import CustomToolbar from "./CustomToolbar";
import EnhancedTableHead from "./EnhancedTableHead";

const showStatement = trans => {
  if (
    (trans.TType === "SecurityTransaction" ||
      trans.TType === "FundTransaction") &&
    (trans.TTypeName === "Buy" ||
      trans.TTypeName === "Sell" ||
      trans.TTypeName === "Redemption" ||
      trans.TTypeName === "Subscription")
  )
    return true;

  return false;
};

const TransactionsTable = props => {
  const { transactions, handleTransactionClick, handleTransactionDownload } =
    props;

  const location = useLocation();
  const isAccount = location.pathname.includes("/dashboard/depaer/")
    ? true
    : false;

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["transactions"] || (isAccount ? 10 : 50)
  );

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("TransactionDate");

  const handleRequestSort = (event, property) => {
    // if (page > 0) setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // parsing transactions data according to Transaction mapping.xlsx logic
  transactions.forEach(transaction => {
    const transactionTypeLabel = transaction.TTypeDisplayName
      ? transaction.TTypeDisplayName
      : transaction.TTypeName;
    const transactionHoldingName =
      transaction.TType === "MoneyTransaction"
        ? transaction.CurrencyCode
        : transaction.HoldingName;

    transaction.TypeLabel = transactionTypeLabel;
    transaction.Name = transactionHoldingName;

    // data needs number type for table sorting to work!
    transaction.Count = Number(transaction.Count);
    transaction.Price = Number(transaction.Price);
    transaction.TotalAmount = Number(transaction.TotalAmount);
    transaction.ShowStatement = showStatement(transaction);
  });

  const [data, setData] = useState(transactions);

  return (
    <TableContainer component={Box}>
      {transactions?.length > 0 && (
        <>
          <CustomToolbar
            isAccount={isAccount}
            transactions={transactions}
            setData={setData}
            page={page}
            setPage={setPage}
          />
          <Box
            sx={{
              maxWidth: "100vw",
              border: 0,
              overflowX: "auto",
              overflowY: "hidden"
            }}
          >
            <Table sx={{ minWidth: 780, border: 0 }} aria-label="simple table">
              <EnhancedTableHead
                isAccount={isAccount}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? data
                      .sort(getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  : data.sort(getComparator(order, orderBy))
                ).map((transaction, key) => (
                  <Fragment key={key}>
                    <TableRow
                      sx={{
                        cursor: transaction.CorporateActionId
                          ? "pointer"
                          : "auto",
                        "&:last-child td": { border: 0 }
                      }}
                      onClick={() =>
                        transaction.CorporateActionId &&
                        handleTransactionClick(transaction.TransactionId)
                      }
                    >
                      <TableCell sx={{ p: 0.5 }}>
                        {transaction.CorporateActionId && (
                          <Typography variant="fairTableCell">
                            {transaction?.toggled ? "-" : "+"}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ p: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {transaction.TransactionDate}
                        </Typography>
                      </TableCell>
                      {!isAccount && (
                        <TableCell sx={{ p: 0.5 }}>
                          <Typography variant="fairTableCell">
                            {transaction.AccountNo}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell sx={{ p: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {transaction.TypeLabel}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {transaction.Name}
                        </Typography>
                      </TableCell>
                      {/* <TableCell sx={{ px: 0.5.4 }}>{transaction.CurrencyCode}</TableCell> */}
                      <TableCell sx={{ p: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {transaction.Count > 0
                            ? convertTransactionAmount(
                                transaction.Count,
                                transaction.TType
                              )
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {transaction.Price && transaction.Price !== "0,00"
                            ? convertToCurrency(transaction.Price) +
                              " " +
                              transaction.CurrencyCode
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {convertToCurrency(transaction.TotalAmount)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: 0.5, textAlign: "right" }}>
                        {transaction.ShowStatement && (
                          <Link
                            onClick={e =>
                              handleTransactionDownload(e, transaction)
                            }
                            sx={{ display: "block", height: "18px" }}
                          >
                            {transaction.Downloaded ? (
                              <DownloadDoneIcon
                                fontSize="small"
                                sx={{ cursor: "pointer" }}
                              />
                            ) : transaction.Downloading ? (
                              <CircularProgress size={18} />
                            ) : (
                              <DownloadIcon
                                fontSize="small"
                                sx={{ cursor: "pointer" }}
                              />
                            )}
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                    {transaction?.toggled && (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          sx={{
                            pl: "5%"
                            // ...(isMobile && {
                            //   py: 0
                            // })
                          }}
                        >
                          <Box
                            className="info-content"
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", sm: "row" },
                              width: "100%"
                            }}
                          >
                            <Box
                              className="transaction-content"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: { xs: "100%", sm: "33.3%" }
                              }}
                            >
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>Typ: </b>
                                {transaction.CorpATDisplayName ||
                                  transaction.CorporateActionType}
                              </Typography>
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>Ex-date: </b>
                                {transaction.TransactionDate}
                              </Typography>
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>Likviddatum: </b>
                                {transaction.SettlementDate
                                  ? transaction.SettlementDate
                                  : "-"}
                              </Typography>
                            </Box>
                            <Box
                              className="transaction-content"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: { xs: "100%", sm: "33.3%" }
                              }}
                            >
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>Antal: </b>
                                {transaction.Count > 0
                                  ? convertTransactionAmount(
                                      transaction.Count,
                                      transaction.TType
                                    )
                                  : "-"}
                              </Typography>
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>
                                  {transaction.CorporateActionType === "Split"
                                    ? "Kurs: "
                                    : "Utdelning: "}
                                </b>
                                {transaction.CorpATShowAmount
                                  ? `${convertToCurrency(transaction.Price)} ${
                                      transaction.SettlementCurrencyCode
                                    }`
                                  : "-"}
                              </Typography>
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>
                                  {transaction.CorporateActionType === "Split"
                                    ? "Sum: "
                                    : "Sum innan skatt: "}
                                </b>
                                {transaction.CorpATShowAmount
                                  ? `${convertToCurrency(transaction.Amount)} ${
                                      transaction.SettlementCurrencyCode
                                    }`
                                  : "-"}
                              </Typography>
                            </Box>
                            <Box
                              className="transaction-content"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: { xs: "100%", sm: "33.3%" }
                              }}
                            >
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>Skatt: </b>
                                {transaction.Tax && transaction.Tax > 0
                                  ? `${convertToCurrency(transaction.Tax)} ${
                                      transaction.SettlementCurrencyCode
                                    }`
                                  : "-"}
                              </Typography>
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>Ratio: </b>
                                {transaction.NumberOfNewShares &&
                                transaction.NumberOfOldShares
                                  ? `${convertShareAmount(
                                      transaction.NumberOfNewShares
                                    )}:${convertShareAmount(
                                      transaction.NumberOfOldShares
                                    )}`
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
      <CustomTableFooter
        type="transactions"
        totalRecords={data?.length || 0}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
        // csvHeaders={csvHeaders}
      />
    </TableContainer>
  );
};

TransactionsTable.propTypes = {
  transactions: PropTypes.array,
  handleTransactionClick: PropTypes.func,
  handleTransactionDownload: PropTypes.func
};

export default TransactionsTable;
