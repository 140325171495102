import React, { useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import useResponsive from "../../../../hooks/useResponsive";
import EnhancedTableHead from "./EnhancedTableHead";
import CustomTableFooter from "../../../CustomTableFooter";

import { convertToCurrency } from "../../../../helpers/functions";
import { getComparator } from "../../../../helpers/MuiTableFunctions";

const FeesTable = props => {
  const isMobileOrTablet = useResponsive("only", "sm");
  const { data } = props;
  // ..and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Date");
  const [page, setPage] = useState(0);

  const handleRequestSort = (event, property) => {
    // if (page > 0) setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer component={Box}>
      {data.length > 0 && (
        <Box
          sx={{
            ...(data.length > rowsPerPage && { minHeight: 350 }),
            maxWidth: "100vw",
            border: 0,
            overflow: "auto"
          }}
        >
          <Table
            sx={{
              minWidth: 900,
              border: 0
            }}
            aria-label="simple table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              isMobileOrTablet={isMobileOrTablet}
            />
            <TableBody>
              {(rowsPerPage > 0
                ? data
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data.sort(getComparator(order, orderBy))
              ).map((fee, index) => (
                <TableRow key={index} sx={{ "&:last-child td": { border: 0 } }}>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell">{fee.Date}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      px: 0,
                      py: 0.5
                    }}
                  >
                    <Typography variant="fairTableCell">
                      {convertToCurrency(fee.FeePaid)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5 }}>
                    <Typography variant="fairTableCell">
                      {convertToCurrency(fee.FeeWithoutRebate)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 0.5, textAlign: "right" }}>
                    <Typography variant="fairTableCell">
                      {convertToCurrency(fee.Earnings)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      {data.length > rowsPerPage && (
        <CustomTableFooter
          type="fees"
          totalRecords={data?.length || 0}
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
        />
      )}
    </TableContainer>
  );
};

FeesTable.propTypes = {
  data: PropTypes.array
};

export default FeesTable;
