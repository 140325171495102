import React, { Fragment, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { getCookie } from "../../../../helpers/functions";
import { getComparator } from "../../../../helpers/MuiTableFunctions";

import CustomTableFooter from "../../../CustomTableFooter";
import CustomToolbar from "./CustomToolbar";
import EnhancedTableHead from "./EnhancedTableHead";

const RecipientsReadTable = props => {
  const { recipients } = props;
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("UpdatedDate");
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["recipientsRead"] || 10
  );

  const handleRequestSort = (event, property) => {
    // if (page > 0) setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [data, setData] = useState(recipients);

  return (
    <TableContainer component={Box}>
      <>
        {recipients.length > 0 && (
          <CustomToolbar
            recipients={recipients}
            setData={setData}
            page={page}
            setPage={setPage}
          />
        )}
        <Box
          sx={{
            maxWidth: "100vw",
            border: 0,
            overflowX: "auto",
            overflowY: "hidden"
          }}
        >
          <Table sx={{ minWidth: 580, border: 0 }} aria-label="simple table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {(rowsPerPage > 0
                ? data
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data.sort(getComparator(order, orderBy))
              ).map((recipient, key) => (
                <Fragment key={key}>
                  <TableRow
                    sx={{
                      "&:last-child td": { border: 0 }
                    }}
                  >
                    <TableCell
                      sx={{
                        px: 0,
                        py: 0.5,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      <Typography variant="fairTableCell">
                        {recipient.RecipientName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        py: 0.5
                      }}
                    >
                      <Typography variant="fairTableCell">
                        {recipient.RecipientEmail}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ px: 0.4, py: 0.5, textAlign: "right" }}>
                      <Typography variant="fairTableCell">
                        {recipient.UpdatedDate
                          ? format(
                              new Date(recipient.UpdatedDate),
                              "yyyy-MM-dd HH:mm"
                            )
                          : "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </Box>
      </>
      <CustomTableFooter
        type="recipientsRead"
        totalRecords={data?.length || 0}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
      />
    </TableContainer>
  );
};

RecipientsReadTable.propTypes = {
  recipients: PropTypes.array
};

export default RecipientsReadTable;
