import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../../theme/styled/FairCloseModalBox";

import useFeesModal from "./useFeesModal";
import SavingsChart from "./SavingsChart";
import ReturnsChart from "./ReturnsChart";
import FeesTable from "./FeesTable";
import { convertToCurrency } from "../../../helpers/functions";

const FeesModal = props => {
  const {
    open,
    toggle,
    limitedFeeAnalysis,
    feeInvestment,
    feeTotalSaved,
    discountReturn,
    rebatesTransactions,
    feeTransactions
  } = useFeesModal(props);

  return (
    <Modal
      open={open}
      onClose={toggle}
      sx={{
        overflow: "scroll"
      }}
    >
      <FairModalBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "auto",
          maxHeight: "90vh",
          width: { xs: "90vw", lg: 1080 },
          paddingTop: 0,
          paddingBottom: 0
        }}
      >
        <Box
          sx={{
            display: "flex",
            px: 4,
            pt: 2.8,
            pb: 2
          }}
        >
          <Typography
            variant="h6"
            color="primary.main"
            sx={{
              height: 22
            }}
          >
            Erhållet rabattvärde
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            minHeight: 300,
            px: 4,
            py: 0,
            overflow: "auto"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: { md: "wrap" },
              alignContent: { md: "space-between" },
              width: "100%",
              height: { xs: "auto", md: 300 },
              px: { xs: 0, md: 3.75 }
            }}
          >
            <Box sx={{ width: { md: "40%" } }}>
              <SavingsChart
                data={{
                  feeTransactions,
                  rebatesTransactions
                }}
              />
            </Box>
            {!limitedFeeAnalysis && (
              <Box
                sx={{
                  width: { md: "40%" },
                  // height: { xs: "50%", md: "100%" },
                  mt: { xs: 4, md: 0 }
                }}
              >
                <ReturnsChart
                  data={{
                    feeTransactions,
                    feeInvestment,
                    rebatesTransactions
                  }}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mt: 2,
              p: 2,
              backgroundColor: "secondary.main",
              borderRadius: 1.5
            }}
          >
            <Box
              sx={{
                width: "100%"
              }}
            >
              <Typography align="left" variant="body2" color="common.black">
                <b>Din investering</b>
                <span style={{ float: "right" }}>
                  {convertToCurrency(feeInvestment)}
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%"
              }}
            >
              <Typography align="left" variant="body2" color="common.black">
                <b>Besparing </b>
                <span style={{ float: "right" }}>
                  {convertToCurrency(feeTotalSaved)}
                </span>
              </Typography>
            </Box>
            {!limitedFeeAnalysis && (
              <Box
                sx={{
                  width: "100%"
                }}
              >
                <Typography align="left" variant="body2" color="common.black">
                  <b>Rabattens avkastning</b>
                  <span style={{ float: "right" }}>{discountReturn}%</span>
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              mt: 2
            }}
          >
            <FeesTable
              data={feeTransactions}
              sx={{
                width: "100%"
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              my: 2
            }}
          >
            <Typography align="left" variant="body3" color="#AAA">
              *Denna summa är baserad på vårt standarderbjudande som är 33%
              Vinstdelning
            </Typography>
            <Typography align="left" variant="body3" color="#AAA">
              **Den erlagda förvaltningsrabatten ska ses som en amortering över
              tid och denna kolumn redovisar vad du har tjänat på din erlagda
              rabatt vid varje enskilt vinstdelningstillfälle
            </Typography>
          </Box>
        </Box>
        <FairCloseModalBox
          sx={{
            height: 50,
            minHeight: 50
          }}
        >
          <Button
            onClick={toggle}
            sx={{
              color: "primary.lighter"
            }}
          >
            Stäng
          </Button>
        </FairCloseModalBox>
      </FairModalBox>
    </Modal>
  );
};

FeesModal.propTypes = {
  open: PropTypes.bool
};

export default FeesModal;
