import { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";
import {
  filterArrayDupsByUniqueElementValue,
  roundNumber,
  sortArrayByPropertyValuesOrder
} from "../../helpers/functions";

const useAccount = () => {
  const errorHandler = useErrorHandler();
  const location = useLocation();
  const { accountId } = useParams();
  const { initAxios } = useAxios();

  const [state, setState] = useState({ loaded: false });

  const fetchAccountData = useCallback(
    async axios => {
      try {
        // get Account details
        const accDetails = await axios.get(`/account/details/${accountId}`);

        const accDetailsData = accDetails.data;
        const { ModelPortfolioId, BenchmarkId, IsSpecial } = accDetailsData;

        const isSpecial = IsSpecial === "1";
        const requiresDefaultChart = ModelPortfolioId && !isSpecial;
        const requiresBenchmarkChart = BenchmarkId && !isSpecial;
        const requiresTransactionsChart = !ModelPortfolioId || isSpecial;

        const requests = [
          {
            id: "summaryHoldings",
            promise: axios.get(`/account/summary/holdings/${accountId}`)
          },
          {
            id: "holdings",
            promise: axios.get(`/account/holdings/${accountId}`)
          },
          {
            id: "totalDeposits",
            promise: axios.get(`/account/deposits/${accountId}`)
          },
          {
            id: "positionsAndRebates",
            promise: axios.get(`account/posrebates/${accountId}`)
          },
          {
            id: "movements",
            promise: axios.get(`/account/movements/${accountId}`)
          },
          {
            id: "highWaterMark",
            promise: axios.get(`/account/highwatermark/${accountId}`)
          },
          {
            id: "chartData",
            promise:
              requiresDefaultChart && !requiresBenchmarkChart
                ? axios.get(
                    `/performance/data/1970-01-01/2050-01-01/${accountId}`
                  )
                : Promise.resolve({ data: {} })
          },
          {
            id: "benchmarkChartData",
            promise: requiresBenchmarkChart
              ? axios.get(
                  `/performance/data/1970-01-01/2050-01-01/${BenchmarkId}/defaultbenchmark/${accountId}`
                )
              : Promise.resolve({ data: {} })
          },
          {
            id: "transactions",
            promise: requiresTransactionsChart
              ? axios.get(`/account/transactions/${accountId}`)
              : Promise.resolve({ data: [] })
          },
          {
            id: "firstTransactionDate",
            promise: axios.get(`/account/firsttransaction/${accountId}`)
          },
          {
            id: "feeTransactions",
            promise: axios.get(`/account/fees/${accountId}`)
          }
        ];

        // Create an array of just the promises
        const promises = requests.map(req => req.promise);

        Promise.all(promises)
          .then(responses => {
            // Map responses to their corresponding identifiers
            const results = requests.map((req, index) => ({
              id: req.id,
              response: responses[index]
            }));

            // Map results to an object with the identifiers as keys
            const mappedResults = results.reduce((acc, { id, response }) => {
              acc[id] = response.data;
              return acc;
            }, {});

            let {
              summaryHoldings,
              holdings,
              movements,
              positionsAndRebates,
              totalDeposits,
              highWaterMark,
              chartData,
              benchmarkChartData,
              transactions,
              firstTransactionDate,
              feeTransactions
            } = mappedResults;

            if (benchmarkChartData?.status === "error") {
              // if benchmarkChartData has error status set it to empty array (fallback for when BenchmarkId references a non existing account)
              console.log(benchmarkChartData.msg);
              benchmarkChartData = {};
            }

            const totalMarketValue =
              summaryHoldings.find(
                holding => holding.instrumentType === "TotalMarketValue"
              ) || {};
            // Change instrumentTypeLabel to Marknadsvärde
            totalMarketValue.instrumentTypeLabel = "Marknadsvärde";
            // Format TotalMarketValue or if is not returned as a holding set its value to "0"
            totalMarketValue.value =
              totalMarketValue?.value?.toFixed(2).toString() || "0";

            const totalReturns = {
              instrumentType: "TotalReturns",
              instrumentTypeLabel: "Värdeutveckling",
              value:
                Number(totalMarketValue.value) -
                (Number(totalDeposits) +
                  Number(positionsAndRebates.positions) +
                  Number(positionsAndRebates.rebates))
            };

            // sort summaryHoldings
            const sortedSummaryHoldings = sortArrayByPropertyValuesOrder(
              summaryHoldings,
              "instrumentType",
              [
                //sort order
                "Currency",
                "Share",
                "ETF",
                "Fund",
                "Warrant",
                "Bond",
                "Receivable",
                "Other"
              ]
            );

            let holdsTtlAvgAmntDirtyBase = 0;
            holdings.forEach(holding => {
              holdsTtlAvgAmntDirtyBase =
                holdsTtlAvgAmntDirtyBase + Number(holding.AvgAmountDirtyBase);
            });

            let transactionsChartData = [];
            if (requiresTransactionsChart && transactions.length > 0) {
              // filter transactions by transaction type
              const chartTransactions = transactions.filter(
                transaction =>
                  transaction.TTypeName === "Buy" ||
                  transaction.TTypeName === "Subscription" ||
                  transaction.TTypeName === "Sell" ||
                  transaction.TTypeName === "Redemption"
              );
              // sort transactions by date asc
              chartTransactions.sort(
                (objA, objB) =>
                  Number(new Date(objA.TransactionDate)) -
                  Number(new Date(objB.TransactionDate))
              );
              // populate transactionsChartData
              chartTransactions.forEach((transaction, index) => {
                let value = 0;

                if (
                  transaction.TTypeName === "Buy" ||
                  transaction.TTypeName === "Subscription"
                ) {
                  value =
                    index > 0
                      ? roundNumber(transactionsChartData[index - 1][1], 2) +
                        roundNumber(transaction.TotalAmount, 2)
                      : roundNumber(transaction.TotalAmount, 2);
                } else if (
                  transaction.TTypeName === "Sell" ||
                  transaction.TTypeName === "Redemption"
                ) {
                  value =
                    index > 0
                      ? roundNumber(transactionsChartData[index - 1][1], 2) -
                        roundNumber(transaction.TotalAmount, 2)
                      : -roundNumber(transaction.TotalAmount, 2);
                }

                transactionsChartData.push([
                  Date.parse(transaction.TransactionDate), // date timestamp
                  value
                ]);
                // Also add today's date with last value
                if (index === chartTransactions.length - 1) {
                  transactionsChartData.push([
                    Date.parse(new Date()), // date timestamp
                    value
                  ]);
                }
              });

              // filter duplicate dates
              transactionsChartData = filterArrayDupsByUniqueElementValue(
                transactionsChartData,
                0
              );
            }

            // Set Fee Transactions data
            let feeTransactionsSum = 0;
            if (feeTransactions.length > 0) {
              feeTransactionsSum = feeTransactions.reduce(
                (acc, transaction) => acc + Number(transaction.FeePaid),
                0
              );
            }

            const feeTransactionsData = {
              feeTransactions,
              feeTransactionsSum
            };

            setState(prevState => ({
              ...prevState,
              loaded: true,
              accDetails: accDetailsData,
              summaryData: {
                chartData,
                benchmarkChartData,
                transactionsChartData,
                firstTransactionDate,
                movements,
                hideDeposit: accDetailsData.HideDeposit === "1",
                summaryHoldings: sortedSummaryHoldings,
                holdings,
                holdsTtlAvgAmntDirtyBase,
                totalMarketValue,
                totalDeposits,
                totalReturns,
                positionsAndRebates,
                highWaterMark,
                feeTransactionsData
              },
              transactions
            }));
          })
          .catch(function (err) {
            errorHandler.serverError(err);
          });
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [accountId, errorHandler]
  );

  useEffect(() => {
    const { axiosInstance, axiosController } = initAxios("private");
    if (!state.loaded) {
      fetchAccountData(axiosInstance);
    }
    return () => axiosController.abort();
  }, [fetchAccountData, initAxios, state.loaded]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loaded: false
    }));
  }, [location.pathname]);

  return {
    state
  };
};

export default useAccount;
