import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { isValid, format } from "date-fns";

import searchIcon from "../../../../assets/search_icon.svg";

const CustomToolbar = props => {
  const { isMobile, filters, users, actions, setFilters, setPage } = props;

  const [isFiltered, setIsFiltered] = useState(false);
  const [dateFromFocused, setDateFromFocused] = useState(false);
  const [dateToFocused, setDateToFocused] = useState(false);
  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);
  const searchInput = useRef(null);

  if (users?.length > 0) {
    // sort users
    users.sort((a, b) => {
      const nameA = a.Name || "";
      const nameB = b.Name || "";

      return nameA.localeCompare(nameB, "sv", { sensitivity: "base" });
    });
  }

  const resetFilters = () => {
    setPage(0);
    setFilters({
      username: null,
      action: null,
      dateFrom: null,
      dateTo: null,
      searchString: ""
    });
    searchInput.current.value = "";
    setIsFiltered(false);
  };

  const requestSearch = searched => {
    setPage(0);
    const searchString = searched.toLowerCase();
    setFilters({ ...filters, searchString });
    setIsFiltered(
      searchString ||
        filters.username ||
        filters.action ||
        filters.dateTo ||
        filters.dateFrom
    );
  };

  const setDateFrom = date => {
    const isValidDate = isValid(date);
    if (!isValidDate) return;

    setPage(0);
    const dateFrom = format(new Date(date), "yyyy-MM-dd");
    setFilters({ ...filters, dateFrom });

    setIsFiltered(
      dateFrom ||
        filters.username ||
        filters.action ||
        filters.dateTo ||
        filters.searchString
    );
  };

  const setDateTo = date => {
    const isValidDate = isValid(date);
    if (!isValidDate) return;

    setPage(0);
    const dateTo = format(new Date(date), "yyyy-MM-dd");
    setFilters({ ...filters, dateTo });

    setIsFiltered(
      dateTo ||
        filters.username ||
        filters.action ||
        filters.dateFrom ||
        filters.searchString
    );
  };

  const handleUserChange = event => {
    const username = event.target.value !== "Alla" ? event.target.value : null;

    setPage(0);
    setFilters({ ...filters, username });
    setIsFiltered(
      username ||
        filters.action ||
        filters.dateFrom ||
        filters.dateTo ||
        filters.searchString
    );
  };

  const handleActionChange = event => {
    const action = event.target.value !== "Alla" ? event.target.value : null;

    setPage(0);
    setFilters({ ...filters, action });
    setIsFiltered(
      action ||
        filters.username ||
        filters.dateFrom ||
        filters.dateTo ||
        filters.searchString
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
          my: 1
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
            display: "flex",
            justifyContent: "left",
            mt: isMobile ? 1.5 : 0
          }}
        >
          <FormControl
            sx={{ width: isMobile ? "100%" : 150, mr: isMobile ? 0 : 1 }}
          >
            <InputLabel
              id="user-type-select-label"
              size="small"
              sx={{ fontSize: "0.8rem" }}
            >
              Användare
            </InputLabel>
            <Select
              size="small"
              labelId="user-type-select-label"
              id="user-type-select"
              value={filters.username || "Alla"}
              label="Användare"
              onChange={handleUserChange}
              sx={{ fontSize: "0.8rem", height: 40 }}
              // MenuProps={
              //   {
              //     // style: { maxHeight: 500, position: "absolute" }
              //     // disableScrollLock: true
              //     // autoFocus: false,
              //     // disableAutoFocus: true
              //   }
              // }
            >
              <MenuItem value={"Alla"} sx={{ fontSize: "0.8rem" }}>
                Alla
              </MenuItem>
              {users &&
                users
                  .filter(user => user.Username !== null)
                  .map(
                    (user, key) =>
                      user && (
                        <MenuItem
                          key={key}
                          value={user.Username}
                          sx={{ fontSize: "0.8rem" }}
                        >
                          {user.Name}
                        </MenuItem>
                      )
                  )}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
            display: "flex",
            justifyContent: "left",
            mt: isMobile ? 1.5 : 0
          }}
        >
          <FormControl
            sx={{ width: isMobile ? "100%" : 150, mr: isMobile ? 0 : 1 }}
          >
            <InputLabel
              id="user-type-select-label"
              size="small"
              sx={{ fontSize: "0.8rem" }}
            >
              Handling
            </InputLabel>
            <Select
              size="small"
              labelId="user-type-select-label"
              id="user-type-select"
              value={filters.action || "Alla"}
              label="Handling"
              onChange={handleActionChange}
              sx={{ fontSize: "0.8rem", height: 40 }}
            >
              <MenuItem value={"Alla"} sx={{ fontSize: "0.8rem" }}>
                Alla
              </MenuItem>
              {actions &&
                actions.map(
                  (action, key) =>
                    action && (
                      <MenuItem
                        key={key}
                        value={action.Value}
                        sx={{ fontSize: "0.8rem" }}
                      >
                        {action.Value}
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
            display: "flex",
            justifyContent: "left",
            mt: isMobile ? 1.5 : 0
          }}
        >
          <DatePicker
            size="small"
            ref={dateFromRef}
            inputFormat="yyyy-MM-dd"
            label="Från"
            sx={{
              width: 150,
              mr: 1
            }}
            value={filters.dateFrom}
            minDate={new Date("2010-01-01")}
            maxDate={(filters.dateTo && new Date(filters.dateTo)) || new Date()}
            onChange={newValue => {
              setDateFrom(newValue);
            }}
            renderInput={({ inputProps, InputProps, ...params }) => (
              <>
                <TextField
                  {...params}
                  size="small"
                  onFocus={() => {
                    setDateFromFocused(true);
                  }}
                  onBlur={event => {
                    const isClearButton =
                      dateFromRef.current.contains(event.relatedTarget) &&
                      event.relatedTarget?.className
                        ?.split(" ")
                        ?.includes("deleteFromIcon");
                    // Do not set focus to false if clicking on the clear button to avoid removing it before the onClick is registered
                    if (!isClearButton) {
                      setDateFromFocused(false);
                    }
                  }}
                  sx={{
                    width: 170,
                    mr: 1,
                    "& .MuiButtonBase-root": {
                      pl: 0
                    },
                    "& .MuiFormLabel-root": { fontSize: "0.8rem" },
                    "& .MuiSvgIcon-root": { fontSize: "1.1rem" },
                    "& .deleteFromIcon": {
                      px: 0,
                      visibility: dateFromFocused ? "visible" : "hidden"
                    }
                  }}
                  InputProps={{
                    ...InputProps,
                    sx: { fontSize: "0.8rem", height: 40 },
                    endAdornment: (
                      <>
                        {filters.dateFrom && (
                          <IconButton
                            className="deleteFromIcon"
                            onClick={() => {
                              setFilters({
                                ...filters,
                                dateFrom: null
                              });
                              dateFromRef.current
                                .querySelector("input")
                                .focus();
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        {InputProps?.endAdornment}
                      </>
                    )
                  }}
                  inputProps={{
                    ...inputProps,
                    placeholder: "åååå-mm-dd"
                  }}
                  InputLabelProps={{
                    sx: { top: "2px" }
                  }}
                />
              </>
            )}
          />
          <DatePicker
            size="small"
            ref={dateToRef}
            inputFormat="yyyy-MM-dd"
            label="Till"
            value={filters.dateTo}
            minDate={
              (filters.dateFrom && new Date(filters.dateFrom)) ||
              new Date("2010-01-01")
            }
            maxDate={new Date()}
            onChange={newValue => {
              setDateTo(newValue);
            }}
            renderInput={({ inputProps, InputProps, ...params }) => (
              <TextField
                {...params}
                size="small"
                onFocus={() => {
                  setDateToFocused(true);
                }}
                onBlur={event => {
                  const isClearButton =
                    dateToRef.current.contains(event.relatedTarget) &&
                    event.relatedTarget?.className
                      ?.split(" ")
                      ?.includes("deleteToIcon");
                  // Do not set focus to false if clicking on the clear button to avoid removing it before the onClick is registered
                  if (!isClearButton) {
                    setDateToFocused(false);
                  }
                }}
                sx={{
                  width: 170,
                  mr: isMobile ? 0 : 1,
                  "& .MuiButtonBase-root": {
                    pl: 0
                  },
                  "& .MuiFormLabel-root": { fontSize: "0.8rem" },
                  "& .MuiSvgIcon-root": { fontSize: "1.1rem" },
                  "& .deleteToIcon": {
                    px: 0,
                    visibility: dateToFocused ? "visible" : "hidden"
                  }
                }}
                InputProps={{
                  ...InputProps,
                  sx: { fontSize: "0.8rem", height: 40 },
                  endAdornment: (
                    <>
                      {filters.dateTo && (
                        <IconButton
                          className="deleteToIcon"
                          onClick={() => {
                            setFilters({
                              ...filters,
                              dateTo: null
                            });
                            dateToRef.current.querySelector("input").focus();
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      {InputProps?.endAdornment}
                    </>
                  )
                }}
                inputProps={{
                  ...inputProps,
                  placeholder: "åååå-mm-dd"
                }}
                InputLabelProps={{
                  sx: { top: "2px" }
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
            display: "flex",
            flexGrow: 1,
            justifyContent: isMobile ? "left" : "right",
            mt: isMobile ? 1.5 : 0
          }}
        >
          <TextField
            size="small"
            placeholder="Sök, fritext"
            type="search"
            onInput={e => requestSearch(e.target.value)}
            sx={{
              ...(isMobile && { flexGrow: 2 })
            }}
            InputProps={{
              inputRef: searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "20px",
                fontSize: "0.8rem",
                height: 40
              }
            }}
          />
        </Box>
      </Box>
      <Box sx={{ width: 90 }}>
        {isFiltered && (
          <Link
            onClick={resetFilters}
            sx={{ display: "block", mt: 0, mr: 0, mb: 2.5, ml: 0.625 }}
          >
            <Typography variant="body3" align="left" sx={{ cursor: "pointer" }}>
              Nollställ filter
            </Typography>
          </Link>
        )}
      </Box>
    </>
  );
};

CustomToolbar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  users: PropTypes.array,
  actions: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func.isRequired
};

export default CustomToolbar;
