import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { CircularProgress } from "@mui/material";

import { getComparator } from "@helpers/MuiTableFunctions";

import CustomTableFooter from "@components/CustomTableFooter";
import CustomToolbar from "./CustomToolbar";
import EnhancedTableHead from "./EnhancedTableHead";

const AuditLogTable = props => {
  const {
    loaded,
    isMobile,
    logs,
    numLogs,
    page,
    setPage,
    handleChangePage,
    filters,
    setFilters,
    users,
    actions,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    handleLogClick
  } = props;

  return (
    <TableContainer component={Box}>
      <CustomToolbar
        isMobile={isMobile}
        // logs={logs}
        filters={filters}
        users={users}
        actions={actions}
        setFilters={setFilters}
        page={page}
        setPage={setPage}
      />
      <Box
        sx={{
          maxWidth: "100vw",
          border: 0,
          overflow: "auto"
        }}
      >
        <Table sx={{ minWidth: 780, border: 0 }} aria-label="simple table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {!loaded ? (
              <TableRow
                sx={{
                  height: "100%",
                  "&:last-child td": { border: 0 }
                }}
              >
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      height: "100%",
                      minHeight: "80vh",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <Fragment>
                {logs &&
                  logs.sort(getComparator(order, orderBy)).map((log, key) => (
                    <Fragment key={key}>
                      <TableRow
                        sx={{
                          cursor: "pointer",
                          "&:last-child td": { border: 0 }
                        }}
                        onClick={() => handleLogClick(log.AuditLogEntryId)}
                      >
                        <TableCell>
                          <Typography>{log?.toggled ? "-" : "+"}</Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0, py: 0.5 }}>
                          <Typography variant="fairTableCell">
                            {log.Created}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0, py: 0.5 }}>
                          <Typography variant="fairTableCell">
                            {log.Name ? log.Name : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0, py: 0.5 }}>
                          <Typography variant="fairTableCell">
                            {log.ActingAsName !== log.Name
                              ? log.ActingAsName || "-"
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0, py: 0.5 }}>
                          <Typography variant="fairTableCell">
                            {log.Action}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {log?.toggled && (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            sx={{
                              pl: "5%",
                              ...(isMobile && {
                                py: 0
                              })
                            }}
                          >
                            <Box
                              className="info-content"
                              sx={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>IP Address: </b>
                                {log.IpNumber}
                              </Typography>
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>UserAgent: </b>
                                {log.UserAgent}
                              </Typography>
                              <Typography
                                variant="fairTableCell"
                                sx={{
                                  my: 1
                                }}
                              >
                                <b>Data: </b>
                                {log.Data ? log.Data : "-"}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
              </Fragment>
            )}

            {logs.length < 1 && (
              <TableRow>
                <TableCell colSpan={7} sx={{ border: 0 }}>
                  Inga resultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <CustomTableFooter
        loaded={loaded}
        type="logs"
        totalRecords={Number(numLogs)}
        data={logs}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
      />
    </TableContainer>
  );
};

AuditLogTable.propTypes = {
  isMobile: PropTypes.bool,
  loaded: PropTypes.bool.isRequired,
  logs: PropTypes.array.isRequired,
  users: PropTypes.array,
  actions: PropTypes.array,
  numLogs: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleLogClick: PropTypes.func.isRequired
};

export default AuditLogTable;
