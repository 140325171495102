import { useState, useEffect, useCallback, useRef } from "react";

import { getCookie } from "@helpers/functions";

import useAxios from "@hooks/useAxios";
import useErrorHandler from "@hooks/useErrorHandler";
import useResponsive from "@hooks/useResponsive";

const useAuditLog = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const isMobile = useResponsive("only", "xs");
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [actions, setActions] = useState([]);
  const [numLogs, setNumLogs] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Created");
  const searchTimeout = useRef(0);

  const [filters, setFilters] = useState({
    username: null,
    action: null,
    dateFrom: null,
    dateTo: null,
    searchString: ""
  });

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["logs"] || 50
  );

  const handleRequestSort = (event, property) => {
    if (page > 0) setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleLogClick = AuditLogEntryId => {
    // Find array index by object prop value
    const logIndex = logs.findIndex(object => {
      return object["AuditLogEntryId"] === AuditLogEntryId;
    });

    let updatedData = [...logs]; // Copy prev state data array
    updatedData[logIndex]["toggled"] = !logs[logIndex].toggled;
    setLogs(updatedData);
  };

  const fetchLogs = useCallback(
    async (filters, page, order, orderBy, rowsPerPage) => {
      const { axiosInstance, axiosController } = initAxios("private");
      axiosGlobalController.current = axiosController;

      try {
        setLoaded(false);
        const { data } = await axiosInstance.post("user/logs", {
          filters,
          page: page || 0,
          order: order || "desc",
          orderBy: orderBy || "created",
          limit: rowsPerPage === -1 ? 100 : rowsPerPage
        });

        setLogs(data.batch);
        setNumLogs(Number(data.numLogs));
        setUsers(data.users);
        setActions(data.actions);
        setLoaded(true);
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [errorHandler, initAxios]
  );

  // Inital load and page/rowsPerPage changes useEffect
  useEffect(() => {
    fetchLogs(filters, page, order, orderBy, rowsPerPage);
    if (!mounted) setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.username,
    filters.action,
    filters.dateFrom,
    filters.dateTo,
    page,
    rowsPerPage,
    order,
    orderBy
  ]);

  // Search useEffect
  useEffect(() => {
    if (mounted) {
      if (searchTimeout.current > 0) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        if (page > 0) setPage(0);
        fetchLogs(filters, page, order, orderBy, rowsPerPage);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.searchString]);

  return {
    isMobile,
    loaded,
    logs,
    numLogs,
    page,
    setPage,
    handleChangePage,
    filters,
    setFilters,
    users,
    actions,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    handleLogClick
  };
};

export default useAuditLog;
